const tematiques_home = [
    {
        id: 'tematiques-home-actualitat',
        titol: 'Actualitat',
        entradeta: 'Qualitat i rigor: tot el que està passant quan està passant',
        icona: null,
        titol_pastilla: 'Actualitat',
        slug: 'actualitat',
        classname: 'actualitat',
        img_pastilla: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/btn/actualitat_btn.png',
        img_pastilla_hover: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/btn/actualitat_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/superdestacat/actualitat_960x540.jpg',
                titol: 'actualitat'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-esports',
        titol: 'esports',
        entradeta: "Transmissions en directe i grans històries de l'esport en català",
        icona: null,
        titol_pastilla: 'Esports',
        slug: 'esports',
        classname: 'esports',
        img_pastilla: 'https://statics.3cat.cat/recursos/3play/img/esports_btn.png',
        img_pastilla_hover: 'https://statics.3cat.cat/recursos/3play/img/esports_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/superdestacat/esports_960x540.jpg',
                titol: 'esports'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-sx3',
        titol: 'SX3',
        entradeta: 'Sèries, pòdcasts i programes infantils',
        icona: 'x3',
        canal: 'sx3',
        slug: 'sx3',
        classname: 'sx3',
        img_pastilla: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/btn/sx3_btn.png',
        img_pastilla_hover: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/btn/sx3_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/superdestacat/sx3_960x540.jpg'
            },
            {
                mida: '312x180',
                rel_name: 'LOGO',
                text: 'https://statics.3cat.cat/recursos/3play/img/logo-sx3.png'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-supercampus',
        titol: 'supercampus',
        entradeta: 'El millor cinema en català i gratuït',
        icona: null,
        titol_pastilla: 'SuperCampus',
        slug: 'supercampus',
        classname: 'supercampus',
        img_pastilla: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/btn/supercampus_btn.png',
        img_pastilla_hover: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/btn/supercampus_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/superdestacat/supercampus_960x540.jpg'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-cultura',
        titol: 'cultura i divulgació',
        entradeta: 'Arts, ciència i continguts per estimular la ment',
        icona: null,
        titol_pastilla: 'Cultura i divulgació',
        slug: 'cultura',
        classname: 'arts',
        type: 'web_3play_seccio',
        img_pastilla: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/btn/cultura_btn.png',
        img_pastilla_hover: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/btn/cultura_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/superdestacat/arts_960x540.jpg',
                titol: 'cultura'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-pelicules',
        titol: 'pel·lícules',
        entradeta: 'El millor cinema en català i gratuït',
        icona: null,
        titol_pastilla: 'Pel·lícules',
        slug: 'pellicules',
        classname: 'pelicules',
        img_pastilla: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/btn/pelicules_btn.png',
        img_pastilla_hover: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/btn/pelicules_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/superdestacat/pelicules_960x540.jpg'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-documentals',
        titol: 'documentals',
        entradeta: 'Grans històries per construir una visió crítica',
        icona: null,
        titol_pastilla: 'Documentals',
        slug: 'documentals-i-reportatges',
        classname: 'documentals',
        img_pastilla: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/btn/documentals_btn.png',
        img_pastilla_hover: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/btn/documentals_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/superdestacat/documentals_960x540.jpg'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-eva',
        titol: 'eva',
        entradeta: "L'espai de la GenZ, en català i sense filtres",
        icona: 'eva_blanc',
        canal: 'eva_blanc',
        slug: 'eva',
        classname: 'eva',
        img_pastilla: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/btn/eva_btn.png',
        img_pastilla_hover: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/btn/eva_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/superdestacat/eva_960x540.jpg'
            },
            {
                mida: '312x180',
                rel_name: 'LOGO',
                text: 'https://statics.3cat.cat/recursos/3play/img/logo-eva.png'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-cuines',
        titol: 'cuines',
        entradeta: 'Per menjar de menú... i a la carta',
        icona: null,
        titol_pastilla: 'Cuines',
        slug: 'cuines',
        classname: 'cuines',
        img_pastilla: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/btn/cuines_btn.png',
        img_pastilla_hover: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/btn/cuines_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/superdestacat/cuines_960x540.jpg',
                titol: 'sx3'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-entreteniment',
        titol: 'entreteniment',
        entradeta: "Les emocions i l'humor que ens connecten",
        icona: null,
        titol_pastilla: 'Entreteniment',
        slug: 'entreteniment',
        classname: 'entreteniment',
        img_pastilla: 'https://statics.3cat.cat/recursos/3play/img/entreteniment_btn.png',
        img_pastilla_hover: 'https://statics.3cat.cat/recursos/3play/img/entreteniment_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://statics.3cat.cat/recursos/3play/img/entreteniment_960x540.jpg',
                titol: 'entreteniment'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-series',
        titol: 'sèries',
        entradeta: 'Originals 3Cat i títols internacionals en català',
        icona: null,
        titol_pastilla: 'Sèries',
        slug: 'series',
        classname: 'series',
        img_pastilla: 'https://statics.3cat.cat/recursos/3play/img/series_btn.png',
        img_pastilla_hover: 'https://statics.3cat.cat/recursos/3play/img/series_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://statics.3cat.cat/recursos/3play/img/series_960x540.jpg',
                titol: 'series'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-podcasts',
        titol: 'pòdcasts',
        entradeta: '3Cat també és pòdcast: oferta sonora per a tots els gustos',
        icona: null,
        titol_pastilla: 'Pòdcasts',
        slug: 'podcasts',
        classname: 'podcasts',
        img_pastilla: 'https://statics.3cat.cat/recursos/3play/img/podcasts_btn.png',
        img_pastilla_hover: 'https://statics.3cat.cat/recursos/3play/img/podcasts_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://statics.3cat.cat/recursos/3play/img/podcasts_960x540.jpg',
                titol: 'podcasts'
            }
        ],
        visible: ['apptv', 'web']
    }
];

const tematiques_home_webview = [
    {
        id: 'tematiques-home-entreteniment',
        titol: 'entreteniment',
        entradeta: '',
        icona: null,
        titol_pastilla: 'Entreteniment',
        slug: 'entreteniment',
        classname: 'entreteniment',
        img_pastilla: 'https://statics.3cat.cat/recursos/3play/img/entreteniment_btn.png',
        img_pastilla_hover: 'https://statics.3cat.cat/recursos/3play/img/entreteniment_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://statics.3cat.cat/recursos/3play/img/entreteniment_960x540.jpg',
                titol: 'entreteniment'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-series',
        titol: 'sèries',
        entradeta: '',
        icona: null,
        titol_pastilla: 'Sèries',
        slug: 'series',
        classname: 'series',
        img_pastilla: 'https://statics.3cat.cat/recursos/3play/img/series_btn.png',
        img_pastilla_hover: 'https://statics.3cat.cat/recursos/3play/img/series_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://statics.3cat.cat/recursos/3play/img/series_960x540.jpg',
                titol: 'sèries'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-podcasts',
        titol: 'pòdcasts',
        entradeta: '3Cat també és pòdcast: oferta sonora per a tots els gustos',
        icona: null,
        titol_pastilla: 'Pòdcasts',
        slug: 'podcasts',
        classname: 'podcasts',
        img_pastilla: 'https://statics.3cat.cat/recursos/3play/img/podcasts_btn.png',
        img_pastilla_hover: 'https://statics.3cat.cat/recursos/3play/img/podcasts_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://statics.3cat.cat/recursos/3play/img/podcasts_960x540.jpg',
                titol: 'podcasts'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-actualitat',
        titol: 'actualitat',
        entradeta: 'Per saber tot el que està passant, quan està passant.',
        icona: null,
        titol_pastilla: 'Actualitat',
        slug: 'actualitat',
        classname: 'actualitat',
        img_pastilla: 'https://statics.3cat.cat/recursos/3play/img/actualitat_btn.png',
        img_pastilla_hover: 'https://statics.3cat.cat/recursos/3play/img/actualitat_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://statics.3cat.cat/recursos/3play/img/actualitat_960x540.jpg',
                titol: 'actualitat'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-esports',
        titol: 'esports',
        entradeta:
            "Tota l'actualitat esportiva i les retransmissions més apassionants amb els equips catalans com a protagonistes.",
        icona: null,
        titol_pastilla: 'Esports',
        slug: 'esports',
        classname: 'esports',
        img_pastilla: 'https://statics.3cat.cat/recursos/3play/img/esports_btn.png',
        img_pastilla_hover: 'https://statics.3cat.cat/recursos/3play/img/esports_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://statics.3cat.cat/recursos/3play/img/esports_960x540.jpg',
                titol: 'esports'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-sx3',
        titol: 'SX3',
        entradeta: "Tota l'oferta infantil i juvenil a les teves mans.",
        icona: 'sx3',
        canal: 'sx3',
        slug: 'sx3',
        classname: 'sx3',
        img_pastilla: 'https://statics.3cat.cat/recursos/3play/img/sx3_btn.png',
        img_pastilla_hover: 'https://statics.3cat.cat/recursos/3play/img/sx3_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://statics.3cat.cat/recursos/3play/img/sx3_960x540.jpg'
            },
            {
                mida: '312x180',
                rel_name: 'LOGO',
                text: 'https://statics.3cat.cat/recursos/3play/img/logo-sx3.png'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-supercampus',
        titol: 'supercampus',
        entradeta: 'El millor cinema en català i gratuït',
        icona: null,
        titol_pastilla: 'SuperCampus',
        slug: 'supercampus',
        classname: 'supercampus',
        img_pastilla: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/btn/supercampus_btn.png',
        img_pastilla_hover: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/btn/supercampus_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://apptv.ccma.cat/apptv/_resources/imatges/tematiques/superdestacat/supercampus_960x540.jpg'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-cultura',
        titol: 'cultura i divulgació',
        entradeta: 'Teatre, música, literatura, tendències... Una oferta cultural heterogènia per a un públic divers.',
        icona: null,
        titol_pastilla: 'cultura i divulgació',
        slug: 'cultura',
        classname: 'arts',
        type: 'web_3play_seccio',
        img_pastilla: 'https://statics.3cat.cat/recursos/3play/img/cultura_btn.png',
        img_pastilla_hover: 'https://statics.3cat.cat/recursos/3play/img/cultura_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://statics.3cat.cat/recursos/3play/img/arts_960x540.jpg',
                titol: 'cultura'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-pelicules',
        titol: 'pel·lícules',
        entradeta: 'Entradeta curta amb text atractiu per "vendre" el contingut.',
        icona: null,
        titol_pastilla: 'Pel·lícules',
        slug: 'pellicules',
        classname: 'pelicules',
        img_pastilla: 'https://statics.3cat.cat/recursos/3play/img/pelicules_btn.png',
        img_pastilla_hover: 'https://statics.3cat.cat/recursos/3play/img/pelicules_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://statics.3cat.cat/recursos/3play/img/pelicules_960x540.jpg'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-documentals',
        titol: 'documentals',
        entradeta: 'Entradeta curta amb text atractiu per "vendre" el contingut.',
        icona: null,
        titol_pastilla: 'Documentals',
        slug: 'documentals-i-reportatges',
        classname: 'documentals',
        img_pastilla: 'https://statics.3cat.cat/recursos/3play/img/documentals_btn.png',
        img_pastilla_hover: 'https://statics.3cat.cat/recursos/3play/img/documentals_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://statics.3cat.cat/recursos/3play/img/documentals_960x540.jpg'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-eva',
        titol: 'eva',
        entradeta: "L'espai de la GenZ, en català i sense filtres",
        icona: 'eva_blanc',
        canal: 'eva_blanc',
        slug: 'eva',
        classname: 'eva',
        img_pastilla: 'https://statics.3cat.cat/recursos/3play/img/eva_btn.png',
        img_pastilla_hover: 'https://statics.3cat.cat/recursos/3play/img/eva_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://statics.3cat.cat/recursos/3play/img/eva_960x540.jpg'
            },
            {
                mida: '312x180',
                rel_name: 'LOGO',
                text: 'https://statics.3cat.cat/recursos/3play/img/logo-eva.png'
            }
        ],
        visible: ['apptv', 'web']
    },
    {
        id: 'tematiques-home-cuines',
        titol: 'cuines',
        entradeta: 'Un bon menú farcit de programes, receptes i documentals sobre el món gastronòmic.',
        icona: null,
        titol_pastilla: 'Cuines',
        slug: 'cuines',
        classname: 'cuines',
        img_pastilla: 'https://statics.3cat.cat/recursos/3play/img/cuines_btn.png',
        img_pastilla_hover: 'https://statics.3cat.cat/recursos/3play/img/cuines_hover_btn.png',
        format: 'pastilla',
        imatges: [
            {
                mida: '1014x570',
                rel_name: 'SUPERDESTACAT',
                text: 'https://statics.3cat.cat/recursos/3play/img/cuines_960x540.jpg',
                titol: 'sx3'
            }
        ],
        visible: ['apptv', 'web']
    }
];

export const getTematiques = (isWebviewActive) => {
    return isWebviewActive ? tematiques_home_webview : tematiques_home;
};
