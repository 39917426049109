import {
    getData,
    getItems,
    getMidaImatge,
    isClientDataUrlReadyToUse,
    isSerie,
    normalizeApiItems,
    normalizeApiItemsRecomana,
    replaceClientDataUrlVariables
} from '@portal-internet/core';
import Slider from 'components/3cat/Slider';
import { useProducte } from 'hooks/3cat/useProducte';
import { useUser } from 'hooks/useUser';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getTematiques } from './Tematiques';

export default function SliderWrapper(props) {
    const {
        nom,
        titol,
        has_link,
        format,
        tipus,
        prefetchedData,
        id,
        urlDadesByProducte,
        field,
        programaId,
        formats,
        estilModul,
        breakpoints,
        hideAvantitol
    } = props;
    let { items } = props;
    const directesIntervalRefresh = 60000; //in millis
    const isDirectes = tipus === 'DIRECTES';
    const isUserContent =
        tipus === 'RECOMANEM' || tipus === 'ESTAS_VEIENT' || tipus === 'ESTAS_VEIENT_PROGRAMA' || tipus === 'MEVA_LLISTA';
    const isDataClient = isUserContent || isDirectes;
    const isSerial = isSerie(formats);
    const initialData = prefetchedData?.[id] ? prefetchedData?.[id] : null;

    const { isAuthenticated, accessToken, uuid, deviceType } = useUser();
    const [titolSlider, setTitolSlider] = useState(nom || titol);
    const [urlDades, setUrlDades] = useState(urlDadesByProducte);
    const [triggerManualRefetch, setTriggerManualRefetch] = useState(false);
    const { isWebviewActive } = useProducte();

    useEffect(() => {
        if (!isAuthenticated) return;
        if (tipus === 'ESTAS_VEIENT_PROGRAMA' && (!isAuthenticated || isSerial)) return;
        if (isUserContent) {
            const clientData = { isAuthenticated, accessToken, uuid, deviceType, programaId };
            const updatedUrlDades = replaceClientDataUrlVariables(urlDadesByProducte, clientData);
            if (isClientDataUrlReadyToUse(updatedUrlDades)) {
                setUrlDades(updatedUrlDades);
                setTriggerManualRefetch(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, accessToken, uuid, deviceType, isUserContent, urlDadesByProducte, tipus]);

    const { data, refetch } = useQuery(
        [
            isDirectes ? 'tira-directes' : 'tira-items',
            { url: urlDades, fetchFromOrigin: isDataClient, moduleName: 'BlocDeContinguts', field }
        ],
        getData,
        {
            enabled: !isUserContent,
            initialData: items || initialData,
            staleTime: isDirectes ? directesIntervalRefresh : Infinity,
            refetchOnMount: (!initialData && !items) || isDataClient ? 'always' : false
        }
    );

    useEffect(() => {
        if (triggerManualRefetch) {
            refetch();
            setTriggerManualRefetch(false);
        }
    }, [triggerManualRefetch, refetch]);
    items = items ? data : getItems(data);

    if (tipus == 'TEMATIQUES' && format == 'PASTILLA') {
        items = getTematiques(isWebviewActive);
    }

    if (isDataClient && tipus === 'RECOMANEM') {
        items = normalizeApiItemsRecomana(items);
    } else if (isDataClient) {
        items = normalizeApiItems(items);
    }

    const hasItems = () => {
        let res;
        if (isDirectes) {
            res = items.find((item) => item.ara_fem !== undefined);
        } else {
            res = items.length > 0;
        }
        return res;
    };

    return items && hasItems() ? (
        <Slider
            has_link={has_link}
            urlDadesByProducte={urlDadesByProducte}
            titol={titolSlider}
            items={items}
            tipus={tipus}
            format={format}
            breakpoints={breakpoints}
            midaImatge={getMidaImatge(format)}
            estilModul={estilModul}
            hideAvantitol={hideAvantitol}
        />
    ) : null;
}
